import React from 'react'
import {Link} from 'gatsby'
import styles from './hamburger.module.css'

export default (props) => (
  <div className={props.className}>
    <div className={styles.bar}></div>
    <div className={styles.bar}></div>
    <div className={styles.bar}></div>
  </div>
)
