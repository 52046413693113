import React, { useState } from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import { StaticQuery, graphql } from 'gatsby'
import Hamburger from './hamburger';

export default function(props) {
  const [navOpen, setNavOpen] = useState(false)


  return(
  <div className={styles.mobileNav}>
    <a onClick={() => setNavOpen(!navOpen)}>
    <Hamburger className={styles.hamburger} />
    </a>
    <ul className={navOpen ? styles.sideNavigationOpen : styles.sideNavigationClosed}>
      <li className={styles.navigationItem}>
        <Link to="/scholarship">Ben's Scholarship</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/story">Ben's Story</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/fentanyl">Fentanyl Facts</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/outdoor-jam">Outdoor Jam</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/news/">News and Updates</Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/about/">About Us</Link>
      </li>
    </ul>
  </div>
  )
}
