import React from 'react'
import {Link} from 'gatsby'
import styles from './footer.module.css'


export default ({ data }) => (
  <div className={styles.footer}>
    <ul className={styles.footerNavigation}>
      <li className={styles.footerLink}><Link to="/">Home</Link></li>
      <li className={styles.footerLink}><Link to="/scholarship">Scholarship</Link></li>
      <li className={styles.footerLink}><Link to="/resources">Resources</Link></li>
      <li className={styles.footerLink}><Link to="/about">About</Link></li>
    </ul>
    <p className={styles.footerCopyright}>&copy; 2021 The Ben Meyer Recovery Foundation, a US 501 (c)(3) public charity, EIN 86-2588328. All rights reserved.</p>
  </div>
)
