import React, { useState } from 'react';
import { Link } from 'gatsby';
import styles from './nav-dropdown.module.css'

export default function NavDropdown(props) {
  const [show, setShow] = useState(false)
  const links = props.links
  const title = props.title

  return (
    <div onMouseEnter={() => setShow(!show)} onMouseLeave={() => setShow(!show)} className={styles.dropdownContainer}>
      <p className={styles.dropdownNavigationItem}>{title} <span style={{fontSize: '14px', marginLeft: '5px'}}>&#9660;</span></p>
      <div className={ show ? styles.hoverDivShow : styles.hoverDivHide} >
        {links.map(link => <Link className={styles.dropdownLink} to={link.url} key={link.url}>{link.text}</Link>)}
      </div>
    </div>
  )
}
