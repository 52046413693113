import React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import styles from './sticky-header.module.css'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

export default function StickyHeader(props) {

  return(
    <div className={styles.stickyContainer}>
      <a href="https://benmeyerfoundation.app.neoncrm.com/forms/bmrf" target="_new"><button className={styles.donateButton}>Donate</button></a>
    </div>
  )

}
