import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import logo from '../../static/logo2.png'
import Hamburger from './hamburger';
import MobileNav from './mobile-nav';
import NavDropdown from './nav-dropdown';

export default function(props) {

  const data = useStaticQuery(graphql`
    query navigationQuery {
      allContentfulAsset(filter: {contentful_id: {eq: "4XIjNgEE4xxtZYOHjQxDO7"}}) {
      edges {
        node {
          file {
            url
          }
        }
      }
    }
    }
  `)

  const aboutLinks = [
    {
      url: '/news',
      text: 'News and Updates'
    },
    {
      url: '/about',
      text: 'About Us'
    }
  ]

  if (props.excludeMobile) {
    return(
        <nav role="navigation" className={styles.navContainer}>
          <a href="/"><img className={styles.navLogo} src={data.allContentfulAsset.edges[0].node.file.url} /></a>
          <ul className={styles.navigation}>
            <li className={styles.navigationItem}>
              <Link to="/scholarship">Ben's Scholarship</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/story">Ben's Story</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/fentanyl">Fentanyl Facts</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/outdoor-jam">Outdoor Jam</Link>
            </li>
            <NavDropdown title="About" links={aboutLinks} />
          </ul>
        </nav>
      )

  } else {
    return(
        <nav role="navigation" className={styles.navContainer}>
          <div className={styles.mobileNavContainer}>
            <MobileNav />
          </div>
          <a href="/"><img className={styles.navLogo} src={data.allContentfulAsset.edges[0].node.file.url} /></a>
          <ul className={styles.navigation}>
            <li className={styles.navigationItem}>
              <Link to="/scholarship">Ben's Scholarship</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/story">Ben's Story</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/outdoor-jam">Outdoor Jam</Link>
            </li>
            <li className={styles.navigationItem}>
              <Link to="/fentanyl">Fentanyl Facts</Link>
            </li>
            <NavDropdown title="About" links={aboutLinks} />
          </ul>

        </nav>
      )


  }

}
